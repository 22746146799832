import React, { Component } from "react";
import { auth } from "../../../services/firebase";
import IndexNavbar from "../../Navbars/IndexNavbar";
import FormHeader from "../../Headers/FormHeader";
import { Link } from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Container} from "reactstrap";

class CopyrightComparisonsMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
        };
    }

    render() {
        return (
        <>
            <IndexNavbar />
            <FormHeader title={"Copyright Image Comparisons Menu"} lead="Hey there, let's get your data!" />
            <Container fluid className="mt--8 pb-5">
                <div className="header-body">
                    <Row className="justify-content-center">
                        <Col md="6" xl="3">
                            <Card className="card-stats" to="/copyright-send" tag={Link}>
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                                Choose your company and marketplace
                                            </CardTitle>
                                            <span className="h2 font-weight-bold mb-0">Receive link to download data</span>
                                        </div>
                                        <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                            <i className="ni ni-send" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <CardSubtitle>
                                        <p className="mt-3 mb-0 text-sm">
                                            {/* Parse through the most recently retrieved sunday listings to see if they're related to the specified brand */}
                                        </p>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                </div>
            </Container>
        </>
        )
    }
}

export default CopyrightComparisonsMenu;
