/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react library for routing
import { Link } from "react-router-dom";
import { logout } from "../../helpers/auth";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

function IndexNavbar() {
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark bg-info"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              src={require("../../assets/img/brand/GrayFalkonLogo.png").default}
            />
          </NavbarBrand>
          <button
            aria-controls="navbar-collapse"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-collapse"
            data-toggle="collapse"
            id="navbar-collapse"
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse
            className="navbar-custom-collapse"
            navbar
            toggler="#navbar-collapse"
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/admin/dashboard">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/brand/GrayFalkonLogo.png")
                          .default
                      }
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink to="/dashboard" tag={Link}>
                  <i className="ni ni-ungroup" />
                  <span className="nav-link-inner--text">Dashboard</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/listingextraction" tag={Link}>
                  <i className="ni ni-basket" />
                  <span className="nav-link-inner--text">
                    Listing Extractor
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/violationsubmission" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span className="nav-link-inner--text">
                    Violation Submitter
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/identifier-search" tag={Link}>
                  <i className="ni ni-books" />
                  <span className="nav-link-inner--text">
                    Rogue Search
                  </span>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to="/pdplistings" tag={Link}>
                  <i className="ni ni-planet" />
                  <span className="nav-link-inner--text">
                    PDP Manager
                  </span>
                </NavLink>
              </NavItem> */}
              {/* <NavItem>
                <NavLink to="/auto-create-accounts" tag={Link}>
                  <i className="ni ni-laptop" />
                  <span className="nav-link-inner--text">
                    Auto Create Accounts
                  </span>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink to="/db-editor" tag={Link}>
                  <i className="ni ni-lock-circle-open" />
                  <span className="nav-link-inner--text">Database Editor</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/history" tag={Link}>
                  <i className="ni ni-archive-2" />
                  <span className="nav-link-inner--text">
                    Tool Usage History
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/seller-data-extraction" tag={Link}>
                  <i className="ni ni-money-coins" />
                  <span className="nav-link-inner--text">
                    Seller Data Extraction
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/" tag={Link} onClick={logout}>
                  <i className="ni ni-user-run" />
                  <span className="nav-link-inner--text">Logout</span>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
